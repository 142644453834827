<template>
  <div>
   <v-app-bar app color="primary" title="info about AppLayoutLinks">
    <v-btn to="/"> Home </v-btn> 
    <v-btn to="/help">Help</v-btn>
   </v-app-bar>

  <v-main>
    <div v-if="demo == 1" class="info5"> AppLayoutBasic Slot Start </div>
    <transition name="fade" mode="out-in">
    <slot />
    </transition>
    <div v-if="demo == 1"  class="info5"> AppLayoutBasic Slot End</div>
  </v-main>

    <v-footer app>
    This is a basic footer
    </v-footer>

  </div>
</template>

<script>
import  {zmlConfig } from '@/api/constants.js'
export default {
  name: "AppLayoutBasic",
  components: {
  },
  data: () => ({
    demo:0,
  }),
  mounted: function () {
      this.demo = zmlConfig.demo
  }
}
</script>

<style scoped>
.info5 {
  height: 5rem;
  background-color: blue;
}

</style>